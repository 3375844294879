<template>
  <div>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
    >
      <multiselect
        v-model="selectedAgents"
        :options="agentList"
        track-by="id"
        label="name"
        :limit="2"
        :limit-text="count => '+' + count"
        class="no-margin"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="false"
        :show-labels="true"
        :searchable="true"
        placeholder="Pick some"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        @select="$v.selectedAgents.$touch"
      />
      <woot-submit-button
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :loading="isAgentListUpdating"
        @click="updateAgents"
      />
    </settings-section>

    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT_SUB_TEXT')"
    >
      <label v-if="!isCallInbox" class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableAutoAssignment"
            v-model="enableAutoAssignment"
            type="checkbox"
            @change="handleEnableAutoAssignment"
          />
          <label for="enableAutoAssignment">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT') }}
        </p>
      </label>

      <div
        v-if="enableAutoAssignment && !isCallInbox"
        class="max-assignment-container"
      >
        <woot-input
          v-model.trim="maxAssignmentLimit"
          type="number"
          :class="{ error: $v.maxAssignmentLimit.$error }"
          :error="maxAssignmentLimitErrors"
          :label="$t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT')"
          @blur="$v.maxAssignmentLimit.$touch"
        />

        <p class="help-text">
          {{ $t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_SUB_TEXT') }}
        </p>

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :disabled="$v.maxAssignmentLimit.$invalid"
          @click="updateInbox"
        />
      </div>
      <div v-if="isCallInbox" class="max-assignment-container">
        <woot-input
          type="number"
          value="1"
          readonly
          :label="$t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT')"
        />

        <p class="help-text">
          {{ $t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_SUB_TEXT') }}
        </p>
      </div>
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.TIMEOUT_TITLE')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.TIMEOUT_SUBTITLE')"
    >
      <label v-if="!isCallInbox" class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableInactivityTimeout"
            v-model="enableInactivityTimeout"
            type="checkbox"
          />
          <label for="enableInactivityTimeout">
            {{ $t('INBOX_MGMT.INACTIVITY_TIMEOUT.ENABLE_TIMEOUT') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.INACTIVITY_TIMEOUT.ENABLE_TIMEOUT_DESCRIPTION') }}
        </p>
      </label>

      <div
        v-if="enableInactivityTimeout && !isCallInbox"
        class="max-assignment-container"
      >
        <p class="subtitle">
          {{ $t('INBOX_MGMT.INACTIVITY_TIMEOUT.SET_TIMEOUT_TITLE') }}
        </p>
        <div class="timeout-wrapper">
          <woot-input
            v-model.trim="timeoutMinutes"
            type="number"
            :label="$t('INBOX_MGMT.INACTIVITY_TIMEOUT.MINUTES')"
            placeholder="5"
            @blur="$v.timeoutMinutes.$touch"
          />
          <woot-input
            v-model.trim="timeoutSeconds"
            type="number"
            placeholder="30"
            :label="$t('INBOX_MGMT.INACTIVITY_TIMEOUT.SECONDS')"
            @blur="$v.timeoutSeconds.$touch"
          />
        </div>

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :disabled="
            $v.timeoutMinutes.$invalid &&
              $v.timeoutSeconds.$invalid &&
              enableInactivityTimeout
          "
          @click="handleEnableInactivityTimeout"
        />
      </div>
      <div v-if="isCallInbox" class="max-assignment-container">
        <woot-input
          type="number"
          value="1"
          readonly
          :label="$t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT')"
        />

        <p class="help-text">
          {{ $t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_SUB_TEXT') }}
        </p>
      </div>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { minValue, required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import SettingsSection from '../../../../../components/SettingsSection';
import onboardingMixin, {
  ACTIONS,
} from '../../../../../mixins/onboardingMixin';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin, configMixin, onboardingMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgents: [],
      currentAgents: [],
      isAgentListUpdating: false,
      enableAutoAssignment: false,
      maxAssignmentLimit: null,
      enableInactivityTimeout: false,
      timeoutMinutes: '',
      timeoutSeconds: '',
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
    }),
    maxAssignmentLimitErrors() {
      if (this.$v.maxAssignmentLimit.$error) {
        return this.$t(
          'INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_RANGE_ERROR'
        );
      }
      return '';
    },
    isCallInbox() {
      return this.inbox.channel_type === 'Channel::Call';
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
    timeoutMinutes(value) {
      if (value !== null && value !== '') {
        this.$v.timeoutMinutes.$touch();
      }
    },
    timeoutSeconds(value) {
      if (value !== null && value !== '') {
        this.$v.timeoutSeconds.$touch();
      }
    },
    enableInactivityTimeout(newValue) {
      if ((!this.timeoutMinutes || !this.timeoutSeconds) && !newValue) {
        this.updateInbox();
      }
      if (newValue) {
        if (this.timeoutMinutes || this.timeoutSeconds) {
          this.updateInbox();
        }
      }
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.enableAutoAssignment = this.inbox.enable_auto_assignment;
      this.enableInactivityTimeout = this.inbox?.reassign_enabled;
      this.timeoutMinutes =
        this.inbox.additional_attributes?.inactivity_timeout?.minutes || '';
      this.timeoutSeconds =
        this.inbox.additional_attributes?.inactivity_timeout?.seconds || '';
      this.maxAssignmentLimit =
        this.inbox.auto_assignment_config?.max_assignment_limit || null;
      this.$nextTick(() => {
        this.$v.timeoutMinutes.$reset();
        this.$v.timeoutSeconds.$reset();
      });
      this.fetchAttachedAgents();
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
        this.currentAgents = inboxMembers;
      } catch (error) {
        //  Handle error
      }
    },
    handleEnableAutoAssignment() {
      this.updateInbox();
    },
    handleEnableInactivityTimeout() {
      this.updateInbox();
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.inbox.id,
          agentList,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));

        this.sendProductFruitsEvent(ACTIONS.INBOX_AGENTS_UPDATED);
        if (this.currentAgents.length > agentList.length)
          this.sendProductFruitsEvent(ACTIONS.INBOX_AGENTS_DELETED);
        else if (this.currentAgents.length < agentList.length) {
          this.sendProductFruitsEvent(ACTIONS.INBOX_AGENTS_ADDED);
        }
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
      this.isAgentListUpdating = false;
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          enable_auto_assignment: this.enableAutoAssignment,
          additional_attributes: {
            inactivity_timeout: {
              minutes: +this.timeoutMinutes,
              seconds: +this.timeoutSeconds,
            },
          },
          reassign_enabled: this.enableInactivityTimeout,
          auto_assignment_config: {
            max_assignment_limit: this.maxAssignmentLimit,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
    maxAssignmentLimit: {
      minValue: minValue(1),
    },
    timeoutMinutes: {
      required,
    },
    timeoutSeconds: {
      required,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.max-assignment-container {
  padding-top: var(--space-slab);
  padding-bottom: var(--space-slab);
}
.timeout-wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}
.subtitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.no-margin {
  margin-bottom: var(--space-small);
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep.multiselect .multiselect__tags {
    display: flex !important;
    align-items: center !important;
    .multiselect__tags-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &::v-deep.multiselect__tags {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (min-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
</style>
